<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
            <tr v-if="loading">
                <td colspan="2" style="padding: 0px !important;height: auto;">
                    <v-progress-linear
                        color="secondary"
                        indeterminate
                        style="border-top-left-radius: 4px;border-top-right-radius: 4px;"
                    ></v-progress-linear>
                </td>
            </tr>
            <tr>
                <th class="text-left" colspan="2" scope="col">
                    {{ $t("fields.document") }}

                    <!--                    <v-btn v-if="total > 1" :disabled="loading" :loading="loading" class="ml-6 float-right"-->
                    <!--                           depressed @click.prevent="reviewAll">-->
                    <!--                        <v-icon left>mdi-file-document-multiple</v-icon>-->
                    <!--                        {{ $t("fields.review_all") }}-->
                    <!--                    </v-btn>-->
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="documents.length === 0">
                <td colspan="2">{{ $t("fields.no_review") }}</td>
            </tr>
            <tr
                v-for="document in documents"
                :key="document.uuid"
            >
                <td>
                    <v-icon class="mr-5">{{ getIconForStatus(document.process_status) }}</v-icon>
                    <router-link :to="'/documents/' + document.uuid + '/review'" style="text-decoration: none;">{{
                            document.name
                        }}
                    </router-link>
                </td>
                <td style="text-align: right">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link @click="cancelReview(document.uuid)">
                                <v-list-item-title class="red--text">{{
                                        $t("fields.cancel_review")
                                    }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </tr>
            <tr v-if="total > documents.length">
                <td colspan="2">
                    {{ $t("fields.more_review", {count: total - documents.length}) }}
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import Accounting from "@/helpers/Accounting";

export default {
    name: "DocumentReview",
    data() {
        return {
            loading: false,
            documents: [],
            total: 0
        };
    },
    mounted() {
        this.fetchDocuments();
    },
    methods: {
        fetchDocuments() {
            this.loading = true;
            Accounting.get("/external/rossum/reviews")
                .then((response) => {
                    this.documents = response.data.documents;
                    this.total = response.data.total;
                }).finally(() => {
                this.loading = false;
            });
        },
        getIconForStatus(status) {
            switch (status) {
                case "to_review":
                    return "mdi-eye";
                case "reviewing":
                    return "mdi-eye-lock";
                case "postponed":
                    return "mdi-clock-alert";
                case "importing":
                    return "mdi-file-import";
                default:
                    break;
            }
        },
        reviewAll() {
            this.$ls.set("review_streak", []);
            this.$router.push("/documents/" + this.documents[0].uuid + "/review");
        },
        cancelReview(doc) {
            this.loading = true;
            Accounting.delete("/documents/" + doc + "/review")
                .then(() => {
                    this.fetchDocuments();
                }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>