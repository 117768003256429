<template>
    <v-card outlined>
        <v-card-title>
            <span class="text-h5">{{ $t("bill_importer.title") }}</span>
        </v-card-title>
        <v-card-subtitle>
            {{ $t("bill_importer.description") }}
        </v-card-subtitle>
        <v-card-text>
            <file-upload accept="application/pdf" v-on:file="filesChange"></file-upload>

            <v-simple-table v-show="uploadedFiles.length > 0" class="mt-3">
                <template v-slot:default>
                    <tbody>
                    <tr
                        v-for="file in uploadedFiles"
                        :key="file.uuid"
                    >
                        <td>{{ truncate(file.name) }}</td>
                        <td style="width: 94px;">
                            <v-progress-linear
                                :color="file.color"
                                :indeterminate="file.indeterminate"
                                height="6"
                                rounded
                                value="100"

                            ></v-progress-linear>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";
import { v4 as uuidv4 } from "uuid";
import Accounting from "@/helpers/Accounting";

export default {
    name: "BillImporter",
    components: {FileUpload},
    data() {
        return {
            uploadedFiles: []
        };
    },
    methods: {
        truncate(str) {
            const MAX_LENGTH = 24;
            if (str.length <= MAX_LENGTH) {
                return str;
            }

            return str.substring(0, MAX_LENGTH) + "...";
        },
        filesChange(file) {
            let requestUUID = uuidv4();

            this.uploadedFiles.push({
                uuid: requestUUID,
                name: file.name,
                color: "primary",
                indeterminate: true
            });

            this.upload(requestUUID, file);
        },
        upload(requestUUID, file) {
            return new Promise(() => {
                let formData = new FormData();
                formData.append("file", file);

                let key = -1;
                for (let i = 0; i < this.uploadedFiles.length; i++) {
                    if (this.uploadedFiles[i].uuid === requestUUID) {
                        key = i;
                    }
                }

                formData.append("name", file.name);

                // Upload file
                Accounting.upload("/documents", formData)
                    .then((response) => {
                        let uuid = response.data.uuid;
                        this.uploadedFiles[key].color = "green";

                        Accounting.get("/documents/" + uuid + "/process").then(() => {
                            this.uploadedFiles[key].indeterminate = false;
                        }).catch(() => {
                            this.uploadedFiles[key].color = "red";
                            this.uploadedFiles[key].indeterminate = false;
                        });

                    })
                    .catch(() => {
                        this.uploadedFiles[key].color = "red";
                        this.uploadedFiles[key].indeterminate = false;
                    });
            });
        }
    }
};
</script>


<style scoped>

</style>