<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
            <tr v-if="loading">
                <td colspan="4" style="padding: 0px !important;height: auto;">
                    <v-progress-linear
                        color="secondary"
                        indeterminate
                        style="border-top-left-radius: 4px;border-top-right-radius: 4px;"
                    ></v-progress-linear>
                </td>
            </tr>
            <tr>
                <th class="text-left" scope="col">
                    {{ $t("fields.invoice") }}
                </th>
                <th class="text-left" scope="col">
                    {{ $t("fields.contact") }}
                </th>
                <th class="text-left" scope="col">
                    {{ $t("fields.due_date") }}
                </th>
                <th class="text-left" scope="col">
                    {{ $t("fields.amount_due") }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="invoices.length === 0">
                <td colspan="4">{{ $t("fields.no_unpaid") }}</td>
            </tr>
            <tr
                v-for="invoice in invoices"
                :key="invoice.uuid"
            >
                <td :class="invoice.past_due ? 'past_due' : ''">
                    <router-link :to="'/incomes/invoices/' + invoice.uuid" style="text-decoration: none;">{{
                            invoice.invoice
                        }}
                    </router-link>
                </td>
                <td>
                    <DynamicElement
                        :uuid="invoice.contact"
                        type="contact"
                    ></DynamicElement>
                </td>
                <td>{{ invoice.due_date }}</td>
                <td>{{ invoice.left_due }}</td>
            </tr>
            <tr v-if="invoices.length > 0">
                <td colspan="3"></td>
                <td>{{ total_left }}</td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import DynamicElement from "@/components/DynamicElement.vue";
import Accounting from "@/helpers/Accounting";

export default {
    name: "UnpaidInvoices",
    components: {DynamicElement},
    data() {
        return {
            loading: false,
            invoices: [],
            total_left: ""
        };
    },
    mounted() {
        this.loading = true;
        Accounting.get("/incomes/invoices/unpaid")
            .then((response) => {
                this.invoices = response.data.invoices;
                this.total_left = response.data.left_due;
            }).finally(() => {
            this.loading = false;
        });
    }
};
</script>

<style scoped>
.past_due a, .past_due td {
    color: #F44336 !important;
}
</style>