<template>
    <div class="ml-10 mr-10">

        <Title :text="this.$t('menu.home')">
            <v-avatar
                color="secondary"
                size="56"
            >
                <img
                    :src="$auth.user.picture"
                    alt="John">
            </v-avatar>
            {{ $t("dashboard.welcome", {name: $auth.user.name}) }}
        </Title>

        <v-row v-if="$store.state.company.uuid != null">

            <v-col cols="12" md="8">

                <DashboardStatistics/>

                <v-row>
                    <v-col md="6">
                        <BillImporter class="mt-10 mb-5"></BillImporter>
                    </v-col>
                </v-row>

            </v-col>

            <v-col cols="12" md="4">
                <v-card class="mb-3" outlined>
                    <v-card-subtitle>{{ $t("dashboard.quick_actions") }}</v-card-subtitle>
                    <v-card-text class="quick_actions">
                        <router-link v-for="action in quickActions" :key="action.target" :to="action.target">
                            <v-icon>{{ action.icon }}</v-icon>
                            {{ action.text }}
                        </router-link>
                    </v-card-text>
                </v-card>

                <h3 class="mb-1 mt-10">{{ $t("dashboard.unpaid_invoice") }}</h3>
                <UnpaidInvoices></UnpaidInvoices>

                <h3 class="mb-1 mt-10">{{ $t("dashboard.document_review") }}</h3>
                <DocumentReview></DocumentReview>

                <!--                <CurrencyConverter></CurrencyConverter>-->

                <!--                <code>-->
                <!--                    <pre>-->
                <!--                        module to list unlinked transactions</pre>-->
                <!--                </code>-->

            </v-col>

        </v-row>

        <div v-else>
            <v-skeleton-loader type="card"></v-skeleton-loader>
        </div>

    </div>
</template>

<script>
import Title from "@/components/TitleBar.vue";
import UnpaidInvoices from "@/components/UnpaidInvoices.vue";
import DashboardStatistics from "@/components/DashboardStatistics.vue";
import BillImporter from "@/components/BillImporter.vue";
import DocumentReview from "@/components/DocumentReview.vue";

export default {
    components: {DocumentReview, BillImporter, DashboardStatistics, UnpaidInvoices, Title},
    name: "Dashboard",
    data() {
        return {
            dateStart: null,
            dateEnd: null,
            quickActions: []
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            }
        ]);

        this.quickActions = [
            {
                icon: "mdi-account-plus",
                text: this.$t("dashboard.actions.add_contact"),
                target: "/contacts/new"
            },
            {
                icon: "mdi-receipt-text-plus",
                text: this.$t("dashboard.actions.add_invoice"),
                target: "/incomes/invoices/new"
            },
            {
                icon: "mdi-wallet-plus",
                text: this.$t("dashboard.actions.add_revenue"),
                target: "/incomes/revenues/new"
            },
            {
                icon: "mdi-receipt-text-plus",
                text: this.$t("dashboard.actions.add_bill"),
                target: "/expenses/bills/new"
            },
            {
                icon: "mdi-wallet-plus",
                text: this.$t("dashboard.actions.add_payment"),
                target: "/expenses/payments/new"
            },
            {
                icon: "mdi-receipt-text-plus",
                text: this.$t("dashboard.actions.add_quote"),
                target: "/quotes/new"
            },
            {
                icon: "mdi-receipt-text-plus",
                text: this.$t("dashboard.actions.add_receipt"),
                target: "/receipts/new"
            }
        ];
    }
};
</script>

<style scoped>
.theme--light.v-toolbar.v-sheet {
    background-color: rgb(250, 250, 250);
}

.quick_actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 15px;
}

.quick_actions a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    border-radius: 4px;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity, background-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    white-space: nowrap;
    padding: 10px;
    width: 115px;
    text-align: center;
    font-size: 0.75rem;
}

.theme--light .quick_actions a {
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: #f5f5f5;
}

.theme--light .quick_actions a:hover {
    background-color: #e3e3e3;
}

.theme--dark .quick_actions a {
    background-color: #272727;
    color: #FFFFFF !important;
}

.theme--dark .quick_actions a:hover {
    background-color: #383838;
}
</style>