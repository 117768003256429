<template>
    <div>
        <v-row class="mb-3">
            <v-col cols="12" md="3">
                <v-card
                    :loading="loading ? 'secondary' : false"
                    outlined
                    shaped
                >
                    <v-card-title class="text-h5">
                        {{ incomes }}
                    </v-card-title>

                    <v-card-subtitle>{{ $t("dashboard.incomes") }}</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                    :loading="loading ? 'secondary' : false"
                    outlined
                    shaped
                >
                    <v-card-title class="text-h5">
                        {{ expenses }}
                    </v-card-title>

                    <v-card-subtitle>{{ $t("dashboard.expenses") }}</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                    :class="resultClass"
                    :loading="loading ? 'secondary' : false"
                    outlined
                    shaped
                >
                    <v-card-title class="text-h5">
                        {{ result }}
                    </v-card-title>

                    <v-card-subtitle>{{ $t("dashboard.results") }}</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card
                    :loading="loading ? 'secondary' : false"
                    outlined
                    shaped
                >
                    <v-card-title class="text-h5">
                        {{ tax }}
                    </v-card-title>

                    <v-card-subtitle>{{ $t("dashboard.tax") }}</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="8">
                <h2>{{ $t("dashboard.reports") }}</h2>
            </v-col>
            <v-col cols="12" md="4">
                <DatePicker v-model="period" :range="true"/>
            </v-col>
        </v-row>

        <v-alert
            v-for="error in errors"
            :key="error"
            border="left"
            type="error"
        >
            {{ error }}
        </v-alert>

        <Bar v-if="!loading" :chart-data="chartData" :chart-options="chartOptions"/>
        <v-card
            v-else
            elevation="0"
            height="400"
            outlined
        >
            <v-overlay
                :absolute="true"
                :opacity="0.1"
            >
                <v-progress-circular
                    color="secondary"
                    indeterminate
                ></v-progress-circular>
            </v-overlay>
        </v-card>

        <v-row class="mt-5">
            <v-col cols="12" md="4">
                <Pie v-if="!loading" :chart-data="pie.chartData" :chart-options="pie.chartOptions"></Pie>
                <v-card
                    v-else
                    elevation="0"
                    height="400"
                    outlined
                >
                    <v-overlay
                        :absolute="true"
                        :opacity="0.1"
                    >
                        <v-progress-circular
                            color="secondary"
                            indeterminate
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { Bar, Pie } from "vue-chartjs/legacy";

import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import store from "@/store";
import DatePicker from "@/components/DatePicker.vue";
import Accounting from "@/helpers/Accounting";

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale);

export default {
    components: {Pie, DatePicker, Bar},
    data() {
        return {
            period: null,

            loading: false,
            incomes: "",
            expenses: "",
            result: "",
            tax: "",

            errors: [],
            chartData: {
                labels: [],
                datasets: []
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },

            pie: {
                chartData: {
                    labels: [],
                    datasets: []
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        };
    },
    mounted() {
        this.preFillDate();
    },
    watch: {
        period() {
            this.fetchData();
        }
    },
    computed: {
        resultClass() {
            if (this.loading || this.result === "") {
                return "";
            }
            if (this.result.startsWith("-")) {
                return "summary_card negative";
            }
            return "summary_card positive";
        }
    },
    methods: {
        preFillDate() {
            if (this.period === null) {
                if (this.$ls.get("dashboard_statistics_period") !== null) {
                    this.period = this.$ls.get("dashboard_statistics_period").split(",");
                } else {
                    let date = new Date(Date.now());
                    this.period = [
                        date.getFullYear() + "-01-01",
                        date.getFullYear() + "-" + ((date.getMonth() + 1) + "").padStart(2, "0") + "-" + (date.getDate() + "").padStart(2, "0")
                    ];
                }
            }
        },
        fetchData() {
            if (this.period === null) {
                return;
            }

            this.$ls.set("dashboard_statistics_period", this.period.join(","));

            this.errors = [];
            let start = this.period[0];
            let end = this.period[1];

            this.loading = true;
            let company = store.state.company.uuid;

            Accounting.get("/companies/" + company + "/statistics/period?start=" + start + "&end=" + end)
                .then(response => {
                    let data = response.data;

                    this.incomes = data.summary.incomes;
                    this.expenses = data.summary.expenses;
                    this.result = data.summary.result;
                    this.tax = data.summary.tax;

                    this.chartData.labels = [];

                    for (const label of data.graph.labels) {
                        this.chartData.labels.push(label);
                    }

                    this.chartData.datasets = [
                        {
                            label: this.$t("dashboard.incomes"),
                            backgroundColor: "#f87979",
                            data: data.graph.dataset.incomes
                        },
                        {
                            label: this.$t("dashboard.expenses"),
                            backgroundColor: "#79acf8",
                            data: data.graph.dataset.expenses
                        },
                        {
                            label: this.$t("dashboard.results"),
                            backgroundColor: "#86f879",
                            data: data.graph.dataset.result
                        }
                    ];

                    this.pie.chartData.labels = [];

                    for (const label of data.pie.labels) {
                        this.pie.chartData.labels.push(label);
                    }

                    this.pie.chartData.datasets = data.pie.dataset;

                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }

};
</script>

<style scoped>
.summary_card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px 4px;
    padding: 2px;
    width: 100%;
    background-size: 400% auto;
    transition: background-position 0.3s ease-in-out, opacity 0.2s ease-in-out;
    mask: linear-gradient(#fff, #000, #000) content-box, linear-gradient(#fff, #000, #000);
    mask-composite: exclude;
    background-position: -50% 0;
    opacity: 1;
}

.summary_card.positive::before {
    background: linear-gradient(90deg, #e2e2e2 0%, #e2e2e2 25%, #C8E6C9 50%, #81C784 75%, #4CAF50 100%);
}

.summary_card.negative::before {
    background: linear-gradient(90deg, #e2e2e2 0%, #e2e2e2 25%, #FFCDD2 50%, #E57373 75%, #F44336 100%);
}

</style>